.rarityContainer {
    display: flex;
}

.rarityMeterCapRare {
    background-color: rgb(61, 102, 238);
    width: 7.5px;
    border-top-right-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
}

.rarityMeterCapLegendary {
    background-color: rgb(238, 191, 61);
    width: 7.5px;
    border-top-left-radius: 7.5px;
    border-bottom-left-radius: 7.5px;
}

.rarityMeterRare{
    background-color: rgb(61, 102, 238);
}

.rarityMeterEpic{
    background-color: rgb(152, 61, 238);
}

.rarityMeterLegendary{
    background-color: rgb(238, 191, 61);
    width: 7.5px;
}

.rarityMeterRare p,
.rarityMeterEpic p,
.rarityMeterLegendary p {
    margin: 0;
}

.nextPullText {
    margin:0;
    margin-right: 10px !important;
    color:white;
    font-weight: bold;
}