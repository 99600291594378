.pullItemBox {
    margin: 10px;
    animation-name: itemAppear;
    animation-duration: 0.5s;
}

.item {
    width: 50px;
}

/*how to add glow to image https://dev.to/ajones_codes/css-smart-image-glow-1deg*/

@keyframes itemAppear {
    0%   {
        opacity: 0;
        transform: scale(0,0);
        filter: drop-shadow(0 0 0rem rgb(255, 217, 0))
        
    }
    50%  {
        opacity: 1;
        transform: scale(1.5,1.5);
        filter: drop-shadow(0 0 1.25rem rgb(255, 217, 0))
    }
    100% {
        opacity: 1;
        transform: scale(1,1);
        filter: drop-shadow(0 0 0rem rgb(255, 217, 0))
    }
}