h1 {
    margin: 0;
    font-size: 75px;
    color:rgba(255, 165, 0, 1);
  }
  
  .display {
    background-color: rgba(0,255,0,0);
    height:100%;
    text-shadow: 2px 2px 4px #000000;
  }
  
  .contentContainer {
    padding: 15px;
  }
  
  .itemContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pullStats {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .pullStatRare,
  .pullStatEpic,
  .pullStatLegendary {
    margin-top: 0;
    margin-bottom: 0;
    margin-left:15px;
    margin-right: 15px;
  }

  .pullStatRare{
    color: rgb(61, 102, 238);
  }

  .pullStatEpic{
    color: rgb(152, 61, 238);
  }

  .pullStatLegendary{
    color: rgb(238, 191, 61);
  }